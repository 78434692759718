import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material'
import { DiscordBlackSvg, DiscordWhiteSvg, TwitterBlackSvg, TwitterWhiteSvg } from '../../assets/images/socialIcon/svgs'
import { useLocation, useNavigate } from 'react-router-dom'

const Wrapper = styled('div')<{dark: string}>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  gap: 10px;
  padding: 5px 10px;
  background-color: ${({ dark }) => dark === 'true' ? 'rgb(10,10,39)' : 'rgb(153, 206, 255)' };
  overflow: hidden;
  
`

const FAQ = styled('div')<{dark: string}>`
  color: ${({ dark }) => dark === 'true' ? 'rgb(239,182,21)' : '#333333' };
  font-family: Righteous-Regular;
  font-weight: bold;
  cursor: pointer;
   
  :hover {
    color:  rgb(239,182,21);
  }
`

const TwitterIcon = styled('div')`
  width: 20px;
  height: 20px;
  padding: 8px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
  border-radius: 10px;


  :hover {
    background-color: #00aced; 
  }
`

const DiscordIcon = styled('div')`
  width: 20px;
  height: 20px;
  padding: 8px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
  border-radius: 10px;


  :hover {
    background-color: rgb(114,137,217); 
  }
`

const Logo = styled('div')`
    width: 200px;
  height: 100%;
  border: 1px aqua solid;
  cursor: pointer;
  box-sizing: border-box;
`

const FlexEnd = styled('div')`
    display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  gap: 16px;
  box-sizing: border-box;
  
`

const Navbar:React.FC = () => {

  const navigate = useNavigate()
  const path = useLocation()
  const [dark, setDark] = useState<boolean>(false)

  useEffect(() => {
    if (path.pathname === '/faq') {
      setDark(true)
      return
    }
    setDark(false)
  }, [path])

  return (
    <Wrapper dark={dark.toString()}>
      <Logo onClick={() => navigate('/')}>
        home
      </Logo>
      <FlexEnd>
        <FAQ dark={dark.toString()} onClick={() => navigate('faq')}> FAQ </FAQ>
        {
          !dark ? (
            <>
              <a href="" className="social-icon"> <TwitterIcon><TwitterBlackSvg /></TwitterIcon></a>
              <a href="" className="social-icon"> <TwitterIcon><DiscordBlackSvg /></TwitterIcon></a>
            </>
          ) : (
            <>
              <a href="" className="social-icon"> <TwitterIcon><TwitterWhiteSvg /></TwitterIcon></a>
              <a href="" className="social-icon"> <DiscordIcon><DiscordWhiteSvg /></DiscordIcon></a>
            </>
          )

        }
      </FlexEnd>

    </Wrapper>

  )
}

export default Navbar
