import { InjectedConnector } from '@web3-react/injected-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { Web3Provider } from '@ethersproject/providers'

export const injected = new InjectedConnector({})

export enum WalletKeys {
  MetaMask = 'MetaMask',
}

export type WalletType = {
  key?: WalletKeys,
  name: string
  icon: string,
  connector?: InjectedConnector,
}

export const supportWallets: WalletType[] = [
  {
    name: 'MetaMask',
    key: WalletKeys.MetaMask,
    icon: '',
    connector: injected,
  },

]

export const SCAN_ADDRESS = {
  44787: 'https://alfajores-forno.celo-testnet.org',
  43114: 'https://api.avax.network/ext/bc/C/rpc',
  4: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
}

export const networkConf = {
  4: {
    chainId: '0x' + parseInt('4').toString(16),
    chainName: 'Ethereum Rinkeby Testnet',
    nativeCurrency: {
      name:'Rinkeby Testnet',
      symbol:'ETH',
      decimals:18,
    },
    rpcUrls: [
      'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
    ],
    blockExplorerUrls: [SCAN_ADDRESS[4]]
  },

  44787: {
    chainId: '0x' + parseInt('44787').toString(16),
    chainName: 'CELO Alfajores Testnet',
    nativeCurrency: {
      name:'',
      symbol:'',
      decimals:18,
    },
    rpcUrls: [
      'https://alfajores-forno.celo-testnet.org'
    ],
    blockExplorerUrls: [SCAN_ADDRESS[44787]]
  },

  43114: {
    chainId: '0x' + parseInt('43114').toString(16),
    chainName: 'Avalanche Mainnet C-Chain',
    nativeCurrency: {
      name:'',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: [
      'https://api.avax.network/ext/bc/C/rpc'
    ],
    blockExplorerUrls: [SCAN_ADDRESS[43114]]
  }
}

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}
