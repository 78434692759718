import { useWeb3React } from '@web3-react/core'
import useSigner from '../useSigner'
import { useCallback, useMemo } from 'react'
import { Contract, ethers } from 'ethers'
import { useQuery } from 'react-query'

const FREE_MINT_CONTRACT_ADDRESS = '0xF4d28eB203589255192814B2179e784E7f475975'

const useFreeMintContract = () => {
  const signer = useSigner()

  const { account, library } = useWeb3React()

  const contract = useMemo(() => {
    if (!account || !signer) {
      return undefined
    }
    return new Contract(FREE_MINT_CONTRACT_ADDRESS, require('./abi/freeMintAbi.json'), signer)
  }, [account,library,signer])

  // const saleState = useCallback(
  //   async () => {
  //     if (!contract) return
  //
  //     console.log(await contract.saleState())
  //
  //     return await contract.saleState()
  //
  //   },
  //   [contract, account],
  // )

  const saleState = useQuery(['SALE_STATE', contract?.address], async () => {
    if (!contract) return

    return await contract.saleState()

  })

  return {
    saleState
  }
}

export default useFreeMintContract
