import React from 'react'

import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { ArrowForwardIosRounded } from '@mui/icons-material'

const Wrapper = styled('div')`
  min-height: 100vh;
  width: 100%;
  background-color: rgb(10,10,39);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`

const Container = styled('div')`
  max-width: 1600px;
  min-width: 70%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled('div')`
    color: ${({ theme }) => theme.palette.primary.main};
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 30px;
`

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: '1px solid #e3e3e3',
  borderBottom: '1px solid #e3e3e3',
  backgroundColor: 'transparent',
  padding:'10px 0',

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosRounded  sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding:0,

  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(0),
  },

  '& .MuiTypography-root': {

  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const TitleText = styled('div')`
  color:${({ theme }) => theme.palette.primary.light};
  font-size: 16px;
  font-weight: bold;
  font-family: Righteous-Regular;
  text-align: left;
`

const DetailText = styled('div')`
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-family: Righteous-Regular;
  text-align: left;
`

const FAQPage:React.FC = () => {

  return (
    <Wrapper>
      <Container>
        <Title>FAQ</Title>
        <Accordion  >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <TitleText>Collapsible Group Item #1</TitleText>
          </AccordionSummary>
          <AccordionDetails>
            <DetailText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </DetailText>
          </AccordionDetails>
        </Accordion>
        <Accordion >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <TitleText>Collapsible Group Item #1</TitleText>
          </AccordionSummary>
          <AccordionDetails>
            <DetailText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </DetailText>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <TitleText>Collapsible Group Item #1</TitleText>
          </AccordionSummary>
          <AccordionDetails>
            <DetailText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </DetailText>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Wrapper>
  )
}

export default FAQPage

