import React, { useCallback, useEffect } from 'react'
import {  styled } from '@mui/material'
import Img1 from '../../assets/images/swiper/1.jpg'
import Img2 from '../../assets/images/swiper/2.jpg'
import Img3 from '../../assets/images/swiper/3.jpg'
import Img4 from '../../assets/images/swiper/4.jpg'
import Img5 from '../../assets/images/swiper/5.jpg'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-creative'

// import required modules
import { EffectCreative,Autoplay } from 'swiper'
import 'swiper/css/autoplay'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../web3/connectors'
import useFreeMintContract from '../../hooks/contract/useFreeMintContract'

const Wrapper = styled('div')`
  height: 100%;  
  width: 100%;
  background-color: rgb(153, 206, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`

const Container = styled('div')`
  max-width: 1600px;
  min-width: 90%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
  box-sizing: border-box;
  
  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex-direction: column;
    padding: 20px 0;
  }
`

const SwiperContainer = styled('div')`
  width: 48%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  .swiper {
    width: 100%;
    height: 100%;
    max-height: 800px;
    max-width: 800px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    border-radius: 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 90%;
  }
`

const MessageBox = styled('div')`
  width: 50%;
  max-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  padding: 1.4rem 0.5rem;
  color: #ffffff;
  flex-direction: column;
  

  background: rgba(0, 0, 0, 0.3);
  
  p{
    text-indent:20px;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 90%;
  }

`

const CustomBtn = styled('div')`
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: 0.4rem;
  padding: 4px 30px;
  font-family: Righteous-Regular;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  7px 7px 20px 0px rgba(0,0,0,.1),
  4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  background: transparent;
  border: none;
  z-index: 1;

  :after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: rgba(0,0,0,.6);
    border-radius: 5px;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
  }
  :hover {
    color: #fff;
  }
  :hover:after {
    left: 0;
    width: 100%; 
  }
 :active {
    top: 2px;
  }
`

const Text = styled('div')`
    color: #fff;
  font-weight: bolder;
`

const Homepage:React.FC = () => {

  const { account ,deactivate, activate, connector, } = useWeb3React()
  const { saleState } = useFreeMintContract()

  const connect = useCallback( () => {
    try {
      activate(injected).then(() => {
        localStorage.setItem('isWalletConnected', 'true')
      })
    }
    catch (ex) {
      console.log(ex)
    }

  }, [ activate])

  useEffect(() => {
    return () => {
      console.log(saleState.data)
    }
  }, [saleState])

  async function disconnectEthWallet() {
    try {
      deactivate()
      localStorage.setItem('isWalletConnected', 'false')
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <Wrapper>

      <Container>
        <SwiperContainer>
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            draggable={true}
            loop={true}

            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            modules={[EffectCreative, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide><img src={Img1} alt={''} /> </SwiperSlide>
            <SwiperSlide><img src={Img5} alt={''} /></SwiperSlide>
            <SwiperSlide><img src={Img3} alt={''} /></SwiperSlide>
            <SwiperSlide><img src={Img2} alt={''} /></SwiperSlide>
            <SwiperSlide><img src={Img4} alt={''} /></SwiperSlide>

          </Swiper>
        </SwiperContainer>
        <MessageBox>
          <p>
            As tech art lovers, we decided to see what would happen if we use some cool pixelated birds to inspire AI for creating the art.
            The stunning versions of the famous owls exceeded all our expectations!
          </p>

          <br />

          <p>
            We decided that the community should see this and created a collection of 10,000 AI-generated owls.
            For us, this will be the beginning of the large-scale development of generative AI art in the NFT community. Tech is the future, and NFT is the future of art!
          </p>

          <br />

          <p>This is the first project of its kind and we want you to be part of it.</p>

          <br />

          <p>Join us for fun and beauty!</p>

          <Text onClick={disconnectEthWallet}>Connected wallet: {account || '-'} </Text>
          {
            account ?

              <CustomBtn>MINT</CustomBtn>

              :
              <CustomBtn onClick={connect}>CONNECT TO METAMASK</CustomBtn>
          }

        </MessageBox>
      </Container>
    </Wrapper>
  )
}

export default Homepage
