import React, { useEffect } from 'react'
import './App.css'
import Homepage from './pages/home'
import { Route, Routes } from 'react-router-dom'
import FAQPage from './pages/faq'
import Navbar from './pages/navbar'
import { styled } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { injected, networkConf } from './web3/connectors'

const Wrapper = styled('div')`
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  background-color: rgb(153, 206, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
`

function App() {
  const { activate, account } = useWeb3React()

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          await activate(injected)
          localStorage.setItem('isWalletConnected', 'true')
        } catch (ex) {
          console.log(ex)
        }
      }
    }
    connectWalletOnPageLoad()
  }, [])

  useEffect(():any => {
    const { ethereum } = window as any

    if (ethereum  && account) {
      console.log(ethereum)
      ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networkConf[4].chainId }]
      }).catch((e: any) => {
        if (e.code === 4902) {
          ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...networkConf[4]
              }
            ],
          })
        }
      })
    }

  },[ account])

  return (
    <div className="App">
      <Wrapper>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="faq" element={<FAQPage />} />
        </Routes>
      </Wrapper>

    </div>
  )
}

export default App
